:root {
  --screen-width: 600px;
  --keyboard-height: 500px;
  --keyboard-width: 350px;
  --header-height: 65px;
  --leaderboard-color: #c9d5d5;
  --leaderboard-header-color: #5a756c;
}

.App {
  text-align: center;
}

body {
  padding: 0%;
  margin: 0;
  background-color: var(--primary-color);
  font-family: 'Montserrat', sans-serif;
}

img {
  width: 50px;
  height: 50px;
  padding: 7px;
 }


.popup {
  background-color: var(--tertiary-color);
  color: var(--popup-text-color);
  padding: 20px 20px 3px 20px;
  display: block;
  text-align: center;
  border-radius: 15px;
}

.popup-leaderboard {
  padding: 5px;
  display: block;
  text-align: center;
  border-radius: 15px;
  height: 100%;
  width: 100%
}

.popup-body {
  padding-top: 20px;
}


.error {
  background-color: #EB4747;
  color: white;
  display: block;
  text-align: center;
  padding: 20px;
  border-radius: 15px;
}

.keyboard {
  width: 95vw;
  max-width: 500px;
  margin: 10px 5px 25px 10px;
}

.form {
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 300px;
  justify-content: center;
  padding: 20px;
  background-color: var(--tertiary-color);
  border-radius: 15px;
}

nav {
  width: 100%;
  margin: 0px;
  border-bottom: 2px solid var(--background-text-color);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: var(--header-height)
}

nav h1 {
  margin: 10px 0px 0px 0px;
  color: var(--background-text-color);
  place-items: center;

}

h1 {
  font-size: 40px;
  margin: 0px;
}

h2 {
  font-size: 30px;
  margin: 5px;
}

h3 {
  font-size: 20px;
  margin-top: -2px;
  font-weight: 100;
}

.loading-text {
  padding-top: 30vh;
  font-size: 20px;
}

.game {
  display: flex;
  align-items: center;
  padding-top: 20px;
  flex-direction: column;
  color: var(--background-text-color)
}

.board {
  max-width: 370px;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  flex-grow: 1
}

.gameArea {
  height: 100%;
}

.row {
  flex: 33%;
  display: flex;
  flex-direction: row;
}

.letter {
  flex: 33%;
  height: 40px;
  width: 40px;
  border: 1px solid grey;
  margin: 4px;
  display: grid;
  place-items: center;
  font-size: 30px;
  font-weight: bolder;
  color: white;
  background-color: var(--board-color);
  font-family: Arial, Helvetica, sans-serif;
}

#correct {
  background-color: #528d4e;
}

#almost {
  background-color: #b49f39;
}

#error {
  background-color: #2f2d2f;
}

.keyboard-line {
  flex: 33%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.key {
  width: 50px;
  height: 50px;
  margin: 3px;
  border-radius: 7px;
  display: grid;
  place-items: center;
  font-size: 12px;
  background-color: var(--secondary-color);
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  cursor: pointer;
}

#big {
  width: 100px;
}

#disabled {
  background-color: #2f2d2f;
}

.hyperLink {
  color: #000;
  padding-bottom: 15px;
}

.submit {
  background-color: var(--secondary-color);
  color: #ffffff;
  border-color: var(--border-color);
  margin: 20px;
  padding: 10px 30px 10px 30px;
  border-radius: 15px 15px 15px 15px;
}

.leaderboard-button {
  background-color: var(--button-alt-background);
  color: #ffffff;
  margin: 20px;
  padding: 10px 30px 10px 30px;
  border-radius: 15px 15px 15px 15px;
}

.spacing {
  padding-top: 200px;
}

.gameOver {
  margin: 0px 5px 10px 5px;
}

.results-button {
  margin-bottom: 10px;
}

footer {
  position:absolute;
  right: 0;
  bottom: -30px;
  color: white;
}

.margin-top {
  margin-top: 20px;
}

.info-text {
  padding-top: 10px;
  font-size: 10pt;
}

#errorMessage {
  max-width: 300px;
}

.leaderboard-submit {
  display: flex;
  gap: 15px;
}

.leaderboard-title {
  color: var(--leaderboard-header-color)
}

.table-container {
  background-color: var(--leaderboard-color);
  color: black;
  padding: 1rem;
  padding-top: 0.25rem;
  border-radius: 8px;
  height: 90%;
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
}

.table-container th {
  font-weight: bolder;
  color: #ffffff;
  background-color: var(--leaderboard-header-color);
  text-align: center;
  padding: 10px;
}

.table-container td {
  text-align: center;
  padding: 10px;
}

.table-container th, .table-container td {
  border: 1px solid #ddd;
}

/* Set a fixed height and enable scrolling */
.table-scroll {
  height: 90%; 
  overflow-y: auto;
}

.close-button {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  right: 15px;
  top: 5px;
  font-size: 24px;
  border: none;
  background: transparent;
}

.no-zoom {
  font-size: 16px;
}

.leaderboard-overlay {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}

.leaderboard-overlay-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 95vw;
  height: 95vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.spinner {
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-top: 3px solid #ffffff;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
  display: inline-block;
}

/* Spinner animation */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}